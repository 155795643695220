import React, { useEffect } from "react";
import "./App.css";

import { BrowserRouter as Router } from "react-router-dom";
import Home from "./pages/Home"
import { GoogleOAuthProvider } from "@react-oauth/google";
import FallBackErrorComponent from "./components/FallBackErrorComponent";
import ReactGA from "react-ga4";
import { REACT_APP_GOOGLE_TRACKING_ID } from "./config";
import TrackPageViews from "./components/GoogleAnalytics";


const App = () => {

  useEffect(()=>{
    ReactGA.initialize(REACT_APP_GOOGLE_TRACKING_ID);
  },[]);


  return (
    <Router future={{
      v7_startTransition: true,
      v7_relativeSplatPath: true,
    }}>
      <TrackPageViews/>
      <GoogleOAuthProvider clientId="392049096273-iubdqdohcpovc0e13qvslcechscliim4.apps.googleusercontent.com">
      <FallBackErrorComponent>
      <Home />
      </FallBackErrorComponent>
      </GoogleOAuthProvider>
    </Router>
  );
}

export default App;
