import SearchIcon from "../../assets/Search.svg";
import FilterDropdown from "../../assets/FilterDropdown.svg";
import ExportsIcon from "../../assets/ExportsIcon.svg";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  capitalizeFirstLetter,
  handleParentClick,
} from "../../utils/clearAllSelectedFilters";
import CircularProgress from "@mui/material/CircularProgress";
import SearchField from "../Search";

const Header = (props) => {
  const {
    parameters,
    heading,
    handleClearSearch,
    handleInputChange,
    updateHandleInputChange,
    searchFieldStyle,
    inputValue,
    buttonDisplay,
    buttonTitle,
    showFiltersDropdown,
    onMouseOver,
    handleParentFilterUnchecked,
    getSubFilterClassName,
    onKeyUp,
    FilterSubOptions,
    handleOnDateHover,
    handleChildClick,
    FindChildFilterSubElements,
    filterOptions,
    setFilterOptions,
    userSelectedFilter,
    setUserSelectedFilter,
    displaySelectedFilters,
    checkClearAllVisibility,
    clearAllSelectedFilters,
    style,
    handleExport,
    exportButtonDisplay,
    exportButtonTitle,
  } = props;

  const url = window.location.search;
  const closedDateSelected = parameters?.includes("completed_date_after");
  const openTicketsSelected = url?.includes("ticket_type=open");

  filterOptions?.forEach((filter) => {
    if (closedDateSelected && filter.type === "open tickets") {
      filter.disabled = true;
    } else if (openTicketsSelected && filter.type === "closedDate") {
      filter.disabled = true;
    } else {
      filter.disabled = false;
    }
  });

  const handleClick = (
    e,
    filter,
    index,
    filterOptions,
    setFilterOptions,
    userSelectedFilter,
    setUserSelectedFilter
  ) => {
    if (filter.type === "closed tickets" || filter.type === "open tickets") {
      handleParentClick(
        e,
        index,
        filter,
        filterOptions,
        setFilterOptions,
        userSelectedFilter,
        setUserSelectedFilter
      );
    }
  };

  return (
    <>
      <span className="span-7">
        <div
          style={{
            fontFamily: "Rubik",
            fontWeight: "500",
            fontSize: "20px",
            color: "var(--Base-Blue-grey-10, #3c3e49)",
          }}
        >
          {heading}
        </div>
        <SearchField
          handleClearSearch={handleClearSearch}
          handleInputChange={handleInputChange}
          searchFieldStyle={searchFieldStyle}
          updateHandleInputChange={updateHandleInputChange}
          heading={heading}
          inputValue={inputValue}
          buttonDisplay={buttonDisplay}
          buttonTitle={buttonTitle}
        />
      </span>
      <div className="div-19">
        <div
          style={{
            display: "flex",
          }}
        >
          <div className="filters">
            <span className="span-10" onClick={showFiltersDropdown}>
              <div className="div-20">Filters</div>
              <img
                loading="lazy"
                src={FilterDropdown}
                className="img-10"
                alt="dropDown"
              />
            </span>
            <div
              className="filters_dropdown"
              data-testid="filters-dropdown-menu"
            >
              {filterOptions.map((filter, index) => {
                return (
                  <div className="filter_dropdown_option" key={index}>
                    <div
                      data-testid={`parent_filter_${filter.type}`}
                      onMouseOver={(e) => {
                        e.stopPropagation();

                        if (!filter.disabled) {
                          onMouseOver(
                            e,
                            index,
                            filter,
                            filterOptions,
                            setFilterOptions,
                            userSelectedFilter,
                            setUserSelectedFilter
                          );
                        }
                      }}
                      onClick={(e) => {
                        if (!filter.disabled) {
                          handleClick(
                            e,
                            filter,
                            index,
                            filterOptions,
                            setFilterOptions,
                            userSelectedFilter,
                            setUserSelectedFilter
                          );
                        }
                      }}
                      className={`parent_filter_option ${
                        filter.isHighlighted ? "elem_highlighted" : ""
                      }`}
                    >
                      <Checkbox
                        checked={filter.checked}
                        disabled={filter.disabled}
                        onClick={(e) => {
                          e.stopPropagation();
                          filter.checked
                            ? handleParentFilterUnchecked(
                                e,
                                filter,
                                filterOptions,
                                setFilterOptions,
                                userSelectedFilter,
                                setUserSelectedFilter
                              )
                            : handleClick(
                                e,
                                filter,
                                index,
                                filterOptions,
                                setFilterOptions,
                                userSelectedFilter,
                                setUserSelectedFilter
                              );
                        }}
                        sx={{
                          color: "rgba(192, 195, 206, 1)",
                          padding: "0",
                          "&.Mui-checked": {
                            color: "rgba(98, 0, 234, 1)",
                          },
                        }}
                      />
                      <div
                        style={{
                          color: filter.disabled ? "rgb(169,169,169)" : "",
                        }}
                      >
                        {capitalizeFirstLetter(filter.type)}
                      </div>
                      {filter.type !== "closed tickets" &&
                        filter.type !== "open tickets" && (
                          <ChevronRightIcon
                            style={{
                              marginLeft: "auto",
                              color: filter.disabled ? "rgb(169,169,169)" : "",
                            }}
                          />
                        )}
                    </div>
                    {filter.showsuboptions && (
                      <div className={getSubFilterClassName(filter.type)}>
                        {filter.type !== "date" &&
                          filter.type !== "closedDate" &&
                          filter.type !== "open tickets" && (
                            <div className="search_box">
                              <div
                                className="sub_filter_search_box_container"
                                style={{
                                  paddingLeft: "0.8rem",
                                  marginRight: "0.4rem",
                                }}
                              >
                                <img src={SearchIcon} alt={"search"} />
                              </div>
                              <div>
                                <input
                                  type="text"
                                  placeholder="Search..."
                                  className="searchInput"
                                  onKeyUp={onKeyUp}
                                />
                              </div>
                            </div>
                          )}
                        {filter.suboptions.map((sub, childIndex) => {
                          return (
                            <FilterSubOptions
                              key={sub.id}
                              handleOnDateHover={handleOnDateHover}
                              handleChildClick={handleChildClick}
                              filter={filter}
                              sub={sub}
                              childIndex={childIndex}
                              FindChildFilterSubElements={
                                FindChildFilterSubElements
                              }
                              index={index}
                              filterOptions={filterOptions}
                              setFilterOptions={setFilterOptions}
                              userSelectedFilter={userSelectedFilter}
                              setUserSelectedFilter={setUserSelectedFilter}
                            />
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="display_selected_filters_dashboard">
            {displaySelectedFilters}

            <Button
              sx={{
                visibility: checkClearAllVisibility,
                color: "#676B7E !important",
                textDecoration: "underline",
                textTransform: "none",
                fontWeight: 400,
              }}
              onClick={clearAllSelectedFilters}
              variant="text"
            >
              Clear all
            </Button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <Button
            data-testid="export_btn"
            onClick={handleExport}
            variant="contained"
            startIcon={
              <img
                loading="lazy"
                src={ExportsIcon}
                className="img-11"
                alt="export"
              />
            }
            disabled={style.display != "none"}
            style={
              style.display == "none"
                ? {
                    background: "var(--Interactive-Bg-T1, #F5F5F5)",
                    color: "var(--True-Grey-9, var(--Base-Grey-9, #262626))",
                    textTransform: "capitalize",
                    height: "100%",
                    display: exportButtonDisplay,
                  }
                : {
                    pointerEvents: "auto",
                    cursor: "not-allowed",
                    width: "6rem",
                    justifyContent: "space-between",
                  }
            }
          >
            {style.display == "none" ? (
              exportButtonTitle
            ) : (
              <CircularProgress
                sx={{
                  height: "1.5rem !important",
                  width: "1.5rem !important",
                  color: "#6200ea",
                }}
              />
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default Header;
