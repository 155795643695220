export const get_available_support_levels = (l1, l2, l3) => {
  const availableLevels = [];

  // Add levels to the array based on their truthy values
  l1 && availableLevels.push("L1");
  l2 && availableLevels.push("L2");
  l3 && availableLevels.push("L3");

  return availableLevels;
};

export const get_list = (array, field) => {
  return array?.map((item) => item?.[field]) || [];
};

export const extractIds = (targetCodes, data) => {
  const filteredIds = data
    .filter((item) => targetCodes.includes(item.code))
    .map((item) => item.id);
  return filteredIds;
};

export const parseTime = (timeValue) => {
  const formatTime = (input) => {
    return parseInt(input) < 10 ? `0${input}` : `${input}`;
  };
  const hours = formatTime(timeValue.$H);
  const mins = formatTime(timeValue.$m);
  return hours + ":" + mins;
};

export const getTitle = (accessType, page, teamName) => {
  if (accessType?.includes("edit")) {
    return `Edit team - ${teamName}`;
  } else if (accessType?.includes("add")) {
    return "Add new team";
  }
  return teamName;
};

export const periodFrequency = ['weekly', 'monthly'];

export const validatedSummaryPeriodType = (summaryPeriodType) => {
  if (Array.isArray(summaryPeriodType)) {
    return summaryPeriodType;
  }

  if (summaryPeriodType) {
    return [summaryPeriodType];
  }

  return [];
};

export const getReportingConfigurationMessage = (reportingFrequency) => {
  if (
    reportingFrequency?.includes("weekly") &&
    reportingFrequency?.includes("monthly")
  ) {
    return (
      <>
        <strong>Weekly:</strong> Slack notifications will trigger every Monday
        at 4:30 AM UTC.
        <br />
        <strong>Monthly:</strong> Slack notifications will trigger on the 1st
        day of each month at 4:30 AM UTC.
      </>
    );
  }

  if (reportingFrequency == "weekly") {
    return (
      <>
        <strong>Weekly:</strong> Slack notifications will trigger every Monday
        at 4:30 AM UTC.
      </>
    );
  }

  if (reportingFrequency == "monthly") {
    return (
      <>
        <strong>Monthly:</strong> Slack notifications will trigger on the 1st
        day of each month at 4:30 AM UTC.
      </>
    );
  }

  return null;
};
