import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ClickupSymbolLogo from "../../assets/ClickupSymbolLogo.png";
import { Typography } from "@mui/material";
import ExportsIcon from "../../assets/ExportsIcon.svg";
import Button from "@mui/material/Button";
import { SlaStatus } from "../SlaStatus";
import { slaStatusMessage } from "../TicketsTable/data";
import { CircularLoader } from "../CircularLoader";
import { CLICKUP_URL, REACT_APP_API_PATH as baseURL } from "../../config";
import { getShortDescription } from "../../utils/TicketsTableFunctions";
import Tooltip from "@mui/material/Tooltip";

const EnhancedTableHead = ({ headCells }) => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            sx={{
              backgroundColor: "var(--Base-Grey-2, #f5f5f5)",
              padding: headCell.disablePadding ? "none" : "0rem",
              textTransform: "none",
              fontSize: "0.8vw",
              width: "6rem",
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const renderCellContent = (key, row, slaStatusMessage) => {
  if (key === "task_title") {
    return (
      <Tooltip title={row.task_title} placement="right-end" arrow>
        {getShortDescription(row.task_title)}
      </Tooltip>
    );
  } else if (key === "clickup_task_id") {
    return (
      <a
        href={`${CLICKUP_URL}${row.clickup_task_id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={ClickupSymbolLogo} alt={"link"} className="clickupLogo" />
      </a>
    );
  } else if (key === "sla_status") {
    return (
      <SlaStatus
        slaStatusType={row[key]}
        tooltipText={slaStatusMessage[row[key]]}
      />
    );
  } else {
    return row[key];
  }
};

export const MaterialTable = ({ ...tableProps }) => {
  const handleScroll = (e) => {
    const { clientHeight, scrollTop, scrollHeight } = e.target;

    if (
      scrollHeight - scrollTop <= clientHeight + 100 &&
      !tableProps.metricIsLoading
    ) {
      tableProps.fetchData();
    }
  };

  useEffect(() => {
    tableProps.fetchData(
      `${baseURL}v1/dashboard_metric/?limit=100${tableProps.parameters}`
    );
  }, [tableProps.parameters]);

  return (
    <div
      style={{
        marginTop: "2rem",
      }}
    >
      <Typography
        component="div"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        div
        style={{
          display: "flex",
        }}
      >
        <Typography component="div" sx={{ fontWeight: 600, fontSize: 18 }}>
          Tickets{" "}
          <span
            style={{
              color: "var(--Blue-Grey-8, var(--Base-Blue-grey-8, #83889E))",
              fontWeight: 400,
            }}
          >
            ({tableProps.countTickets})
          </span>
        </Typography>
        <div
          style={{
            display: "flex",
          }}
        >
          {tableProps.exportIsLoading && (
            <CircularLoader
              sx={{
                marginRight: "1rem",
                width: "2rem !important",
                height: "2rem !important",
              }}
            />
          )}

          <Button
            data-testid="export_btn"
            onClick={tableProps.handleExport}
            variant="contained"
            startIcon={
              <img
                loading="lazy"
                src={ExportsIcon}
                className="img-11"
                alt="export"
              />
            }
            style={{
              background: "var(--Interactive-Bg-T1, #F5F5F5)",
              color: "var(--True-Grey-9, var(--Base-Grey-9, #262626))",
              textTransform: "capitalize",
              height: "100%",
            }}
          >
            Export
          </Button>
        </div>
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer
            sx={{
              mt: 2,
              maxHeight: {
                sm: "35vh",
                md: "30vh",
                lg: "35vh",
                xl: "37vh",
                xxl: "38vh",
              },
              overflow: "auto",
              scrollbarWidth: "none",
            }}
            className="metricTableContainer"
            onScroll={handleScroll}
          >
            <Table
              stickyHeader
              aria-labelledby="sticky table"
              className="metricTableData"
            >
              <EnhancedTableHead headCells={tableProps.tableHeadCells} />
              <TableBody>
                {tableProps.tableData.map((row) => (
                  <TableRow key={row.id}>
                    {tableProps.tableKeys.map((key) => (
                      <TableCell
                        key={key}
                        align="center"
                        sx={{
                          paddingY: "0rem",
                          textAlign: "-webkit-center",
                          fontSize: "0.8vw",
                        }}
                      >
                        {renderCellContent(key, row, slaStatusMessage)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
                {tableProps.metricIsLoading && (
                  <TableRow>
                    <TableCell
                      colSpan={tableProps.tableKeys.length}
                      align="center"
                    >
                      <CircularLoader />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </div>
  );
};
