import Checkbox from "@mui/material/Checkbox";
import { defaultUserSelectedFilter } from "../pages/Dashboard/data";
import {
  handleChildFilterUnchecked,
  handleParentClick,
  filterUncheckedForDateCt,
  handleDateChange,
  findUpdatedSuboptionsList,
} from "./clearAllSelectedFilters";

export const clearAllSelectedFiltersForDashbooard = (
  setUserSelectedFilter,
  setFilterOptions,
  filterOptions,
  removeCheckFromAllSuboptions
) => {
  setFilterOptions(
    filterOptions.map((filterOption) => {
      return {
        ...filterOption,
        checked: false,
        suboptions: removeCheckFromAllSuboptions(
          filterOption.suboptions,
          filterOption.type
        ),
      };
    })
  );

  setUserSelectedFilter((userSelectedFilter) => {
    return {
      ...defaultUserSelectedFilter,
      spanKey: "Last 7 days",
      startDate: findDashboardDateValue("Last 7 days"),
      endDate: "",
    };
  });
};

export function updateUserSelectedFilter({
  e,
  parentFilter,
  indices,
  sub,
  callbacks,
  state,
}) {
  const { parentFilterType, childFilterId, childFilterType } = parentFilter;
  const { parentFilterIndex, childFilterIndex } = indices;
  const {
    handleChildFilterUnchecked,
    setFilterOptions,
    setUserSelectedFilter,
  } = callbacks;
  const { userSelectedFilter, filterOptions } = state;

  if (sub.checked === true) {
    handleChildFilterUnchecked(
      e,
      parentFilterType,
      sub,
      filterOptions,
      setFilterOptions,
      userSelectedFilter,
      setUserSelectedFilter
    );
    return;
  }

  const newUserSelectedFilter = {
    ...userSelectedFilter,
    [parentFilterType]: [
      ...userSelectedFilter[parentFilterType],
      childFilterId,
    ],
  };

  setFilterOptions(
    filterOptions.map((item, index) =>
      index === parentFilterIndex
        ? {
            ...item,
            checked: true,
            isHighlighted: true,
            suboptions: findUpdatedSuboptionsList(
              item.suboptions,
              childFilterIndex,
              parentFilterType
            ),
          }
        : { ...item, isHighlighted: false }
    )
  );

  setUserSelectedFilter(newUserSelectedFilter);
}

export const FilterSubOptions = ({
  handleChildClick,
  filter,
  sub,
  childIndex,
  index,
  FindChildFilterSubElements,
  filterOptions,
  setFilterOptions,
  userSelectedFilter,
  setUserSelectedFilter,
}) => {
  const isCustomDate = sub.type === "Custom date";

  const onClickHandler = (e) => {
    if (isCustomDate || filter.type !== "Custom date") {
      handleChildClick(
        e,
        filter.type,
        sub.id,
        index,
        childIndex,
        sub,
        sub.type
      );
    }
  };

  return (
    <div
      className="sub_filter_checkbox"
      style={{ paddingLeft: "0.8rem" }}
      onClick={onClickHandler}
    >
      {FindChildFilterSubElements(
        filter.type,
        sub,
        filterOptions,
        setFilterOptions,
        userSelectedFilter,
        setUserSelectedFilter
      )}
    </div>
  );
};

export const findSubOptionsStatus = (suboptions, subType) =>
  suboptions.map((item) =>
    item.type === subType ? { ...item, checked: false } : item
  );

export const checkIfParentFilterIsChecked = (suboptions) =>
  suboptions.filter((item) => item.checked).length > 0;

export const implementSetFilterOptions = (filterOptions, key, subType) =>
  filterOptions.map((filterOption) =>
    filterOption.type === key
      ? {
          ...filterOption,
          checked: checkIfParentFilterIsChecked(filterOption.suboptions),
          suboptions: findSubOptionsStatus(filterOption.suboptions, subType),
        }
      : filterOption
  );

export const getDateFilterChipStyle = () => {
  return {
    [`& .MuiChip-deleteIcon`]: {
      color: "var(--Base-Purple-5, #651FFF)",
      fontFamily: "Rubik, sans-serif",
      fontSize: "0.875rem",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "1.25rem",
      letterSpacing: "0.00875rem",
      marginBottom: "-0.15rem",
    },
  };
};

export const formatDateValue = (dashboardDate) => {
  const selectedDate = new Date(dashboardDate);
  const fullYear = selectedDate.getFullYear();
  const month = selectedDate.getMonth() + 1;
  const day = selectedDate.getDate();

  const updatedMonth = month < 10 ? `0${month}` : month;
  const updatedDate = day < 10 ? `0${day}` : day;

  return `${fullYear}-${updatedMonth}-${updatedDate}`;
};

export const handleParentFilterUnchecked = (
  e,
  filter,
  filterOptions,
  setFilterOptions,
  userSelectedFilter,
  setUserSelectedFilter
) => {
  filterUncheckedForDateCt(
    filter,
    setFilterOptions,
    filterOptions,
    userSelectedFilter,
    setUserSelectedFilter
  );

  if (filter.type === "closed tickets") {
    let tempUserSelectedFilter = userSelectedFilter;

    delete tempUserSelectedFilter["closed tickets"];

    setFilterOptions((filterOptions) => {
      return filterOptions.map((filterOption) => {
        if (filterOption.type === "closed tickets") {
          return {
            ...filterOption,
            checked: false,
          };
        } else {
          return filterOption;
        }
      });
    });

    setUserSelectedFilter((userSelectedFilter) => {
      return {
        ...tempUserSelectedFilter,
      };
    });
  } else if (filter.type === "open tickets") {
    let tempUserSelectedFilter = userSelectedFilter;

    delete tempUserSelectedFilter["open tickets"];

    setFilterOptions((filterOptions) => {
      return filterOptions.map((filterOption) => {
        if (filterOption.type === "open tickets") {
          return {
            ...filterOption,
            checked: false,
          };
        } else {
          return filterOption;
        }
      });
    });

    setUserSelectedFilter((userSelectedFilter) => {
      return {
        ...tempUserSelectedFilter,
      };
    });
  } else console.log("HandleParentFalse");

  e.stopPropagation();
};

const generateAssigneesString = (userSelectedFilter, item) => {
  return userSelectedFilter[item]?.length > 0
    ? `&${item}=${userSelectedFilter[item].join(",")}`
    : "";
};

const generateTeamsOrSubTeamsString = (userSelectedFilter, item) => {
  let updatedTeam = "";
  if (item === "teams") updatedTeam = "team";
  else if (item === "sub_teams") updatedTeam = "sub_team";
  return userSelectedFilter[item]?.length > 0
    ? userSelectedFilter[item]
        .map((itemType) => `&${updatedTeam}=${itemType}`)
        .join("")
    : "";
};

export const generateDashboardDateParamString = (
  dashboardDate,
  isCompletedDate
) => {
  const timeText = "23:59";
  let startDateQueryKey = isCompletedDate
    ? "completed_date_after"
    : "created_date_after";
  let endDateQueryKey = isCompletedDate
    ? "completed_date_before"
    : "created_date_before";

  let startDate =
    dashboardDate["From"].length > 0
      ? `&${startDateQueryKey}=${dashboardDate.From} 00:00`
      : "";
  let endDate =
    dashboardDate["To"].length > 0
      ? `&${endDateQueryKey}=${dashboardDate.To} ${timeText}`
      : "";

  return `${startDate}${endDate}`;
};

export const generateParamString = (
  userSelectedFilter,
  handleSetBeforeParamString,
  useCreatedDates = false
) => {
  let pstr = "";

  Object.keys(userSelectedFilter).forEach((item) => {
    switch (item) {
      case "assignees":
        pstr += generateAssigneesString(userSelectedFilter, item);
        break;

      case "teams":
      case "sub_teams":
        pstr += generateTeamsOrSubTeamsString(userSelectedFilter, item);
        break;

      case "closed tickets":
        pstr +=
          userSelectedFilter[item].length > 0 ? `&ticket_type=closed` : "";
        break;

      case "open tickets":
        pstr += userSelectedFilter[item].length > 0 ? `&ticket_type=open` : "";
        break;

      default:
        break;
    }
  });

  if (handleSetBeforeParamString) {
    handleSetBeforeParamString(pstr);
  }

  return pstr;
};

export const checkRelevantDateSubtype = (suboptions, dateType) => {
  return suboptions.map((suboption) => {
    if (suboption.type === dateType) {
      return {
        ...suboption,
        checked: true,
      };
    } else
      return {
        ...suboption,
        checked: false,
      };
  });
};

export const findDashboardDateValue = (childFiltertype) => {
  if (childFiltertype === "Today")
    return subtractDaysToDateString(new Date(), 0);
  else if (childFiltertype === "Yesterday") {
    return subtractDaysToDateString(new Date(), 1);
  } else if (childFiltertype === "Last 7 days") {
    return subtractDaysToDateString(new Date(), 6);
  } else if (childFiltertype === "Last 14 days") {
    return subtractDaysToDateString(new Date(), 13);
  } else if (childFiltertype === "Last 30 days") {
    return subtractDaysToDateString(new Date(), 29);
  }
};

export const handleDateCreatedDueDate = (
  e,
  childFilter,
  filterOptions,
  setFilterOptions,
  userSelectedFilter,
  setUserSelectedFilter
) => {
  if (childFilter.type === "Custom date") {
    return;
  }

  const dashboardDate = findDashboardDateValue(childFilter.type);
  const suboptionType = "From";

  childFilter.showsuboptions = true;

  handleDateChange(
    formatDateValue(dashboardDate),
    suboptionType,
    childFilter.type,
    filterOptions,
    setFilterOptions,
    userSelectedFilter,
    setUserSelectedFilter
  );
};

export const subFilterAssigneeNameStyle = (userName) => {
  return String(userName).charAt(0);
};

export const subFilterNameStyle = (subFilterName) => {
  return subFilterName.charAt(0).toUpperCase() + subFilterName.slice(1);
};

export const formatDateFilterDropdown = (dateString) => {
  if (dateString) {
    const dashboardDate = new Date(dateString);
    const day =
      dashboardDate.getDate() < 10
        ? `0${dashboardDate.getDate()}`
        : dashboardDate.getDate();
    const month =
      dashboardDate.getMonth() + 1 < 10
        ? `0${dashboardDate.getMonth() + 1}`
        : dashboardDate.getMonth() + 1;
    const year = dashboardDate.getFullYear().toString().slice(2);

    return `${day}/${month}/${year}`;
  }
  return "";
};

const subtractDaysToDateString = (dateString, noOfDays) => {
  const date = new Date(dateString);

  date.setDate(date.getDate() - noOfDays);

  const formattedDate = date.toISOString().slice(0, 10);

  return formattedDate;
};

export const FindChildFilterSubElements = (
  parentFilterType,
  childFilter,
  filterOptions,
  setFilterOptions,
  userSelectedFilter,
  setUserSelectedFilter
) => {
  return (
    <div className="assignee_sub_filter">
      {parentFilterType === "assignees" ? (
        <div
          className="firstLetter"
          style={{
            width: "28px",
            height: "28px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bolder",
            borderRadius: "28px",
            color: "white",
            backgroundColor: `${childFilter.colour}`,
          }}
        >
          {subFilterAssigneeNameStyle(childFilter.type)}
        </div>
      ) : (
        ""
      )}
      <div
        style={{
          fontWeight: childFilter.checked ? "600" : "normal",
          textAlign: parentFilterType === "assignees" ? "center" : "left",
        }}
      >
        {subFilterNameStyle(childFilter.type)}
      </div>
      <div>
        <Checkbox
          checked={childFilter.checked}
          onClick={(e) => {
            childFilter.checked
              ? handleChildFilterUnchecked(
                  e,
                  parentFilterType,
                  childFilter,
                  filterOptions,
                  setFilterOptions,
                  userSelectedFilter,
                  setUserSelectedFilter
                )
              : handleParentClick(
                  e,
                  parentFilterType,
                  childFilter,
                  filterOptions,
                  setFilterOptions,
                  userSelectedFilter,
                  setUserSelectedFilter
                );
          }}
          sx={{
            color: "rgba(192, 195, 206, 1)",
            "&.Mui-checked": {
              color: "rgba(98, 0, 234, 1)",
            },
          }}
          className="chip_checkbox"
        />
      </div>
    </div>
  );
};
