import { Button } from "@mui/material";

export const SaveCancel = ({
  handleUserSave,
  saveButtonStatus,
  handleUserCancel,
}) => {
  return (
    <>
      <Button
        onClick={handleUserSave}
        sx={{
          backgroundColor: "var(--Interactive-Bg-P1, #00e676)",
          color: "var(--Interactive-Label-P1-P2-E1, #262626)",
          borderRadius: " 8px",
          fontFamily: "sans-serif",
          fontSize: "12px",
          lineHeight: "0",
          "&:hover": {
            backgroundColor: "var(--Interactive-Bg-P1, #1aff8f)",
          },
        }}
        disabled={!saveButtonStatus}
        style={
          !saveButtonStatus
            ? {
                pointerEvents: "auto",
                cursor: "not-allowed",
              }
            : {}
        }
      >
        Save
      </Button>
      <Button
        onClick={handleUserCancel}
        sx={{
          backgroundColor: "var(--Interactive-Bg-Sec, #FFF);",
          color: "var(--Interactive-Label-P1-P2-E1, #262626)",
          borderRadius: " 8px",
          fontFamily: "sans-serif",
          fontSize: "12px",
          border: "1px solid rgba(179, 136, 255, 0.30)",
          marginLeft: "1rem",
        }}
      >
        Cancel
      </Button>
    </>
  );
};
