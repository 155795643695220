import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import DateRangeIcon from "@mui/icons-material/DateRange";

export const MenuOptions = ({ row,handleClick, optionsList, iconColor }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        className="menuButtonMenu"
        onClick={handleOpenMenu}
        size="small"
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        sx={{
          padding: "0",

          height: "inherit",
          "& .MuiMenu-paper": {
            minHeight: "2.3rem",
            overflow: "hidden",
            borderRadius: "10px",
          },
          "& .MuiMenu-list": {
            padding: 0,
          },
        }}
      >
        {optionsList.map((option, index) => (
          <MenuItem
          key={index}
            onClick={(event)=>handleClick(event,option,row)}
            sx={{
              padding: "4px 8px 0px 0px",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "#f3ebff",
                cursor: "pointer",
              },
            }}
          >
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
            >
              <DateRangeIcon
                fontSize="small"
                style={{ color: String(iconColor) }}
              />
            </IconButton>
            {option}
          </MenuItem>

          
        ))}
      </Menu>
    </>
  );
};
