import { useState, useEffect, useRef } from "react";
import { REACT_APP_API_PATH as baseURL } from "../../config";
import {
  customFetch,
  customAPICallWithPayload,
} from "../../services/requestServices";
import {
  redirectToTicketSumary,
  isManagerOrSuperUser,
} from "../../utils/common_functions";
import { ErrorComponent } from "../Error";
import TableWithExpandableRows from "../../components/TableWithExpandableRows";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const SnackBarText = (option) => {
  const textmessage = {
    "Create ROTA":
      "Shift created successfully, please check your email for further info",
    "Enable ROTA": "Shift enabled successfully",
    "Disable ROTA": "Shift disabled successfully",
  };
  return textmessage[option];
};

function Teams() {
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCal, setIsLoadingCal] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [url, setUrl] = useState(`${baseURL}v1/team/?limit=15`);
  const [rotaAction, setRotaAction] = useState("");
  const teamsController = useRef(null);
  const searchController = useRef(null);
  const prevFetchUrl = useRef(null);

  const fetchData = async (customUrl) => {
    const fetchUrl = customUrl || url;
    if (fetchUrl === prevFetchUrl.current || !fetchUrl) return;
    prevFetchUrl.current = fetchUrl;
    setIsLoading(true);

    const data = await customFetch(fetchUrl, "GET", null);
    if (searchController.current) {
      return;
    }
    if (data.next != null) setUrl(data.next);
    else setUrl("");
    setRowData([...rowData, ...data.results]);
    teamsController.current = null;
    setIsLoading(false);
  };

  const fetchTeamsSearchList = async (searchText) => {
    if (teamsController.current) {
      teamsController.current.abort();
    }

    if (searchController.current) {
      searchController.current.abort();
    }

    searchController.current = new AbortController();
    const signal = searchController.current.signal;

    setRowData([]);
    setIsLoading(true);

    const searchUrl = searchText
      ? `${baseURL}v1/team/?limit=15&search=${searchText}`
      : `${baseURL}v1/team/?limit=15`;

    const searchTeamData = await customFetch(searchUrl, "GET", signal);

    setRowData(searchTeamData?.results);
    setUrl(searchTeamData?.next);
    setIsLoading(false);
    localStorage.setItem("search", searchText);
    searchController.current = null;
  };

  const fetchMoreOnBottomReached = () => {
    if (url != "") fetchData(url);
  };

  const apicallForCalendar = async (method, payload, shift_id, option) => {
    const item_id = method == "PUT" ? `${shift_id}` + "/" : "";
    let fetchURL = `${baseURL}v1/shift_mapping/${item_id}`;

    setIsLoadingCal(true);
    setRotaAction(option);
    try {
      const response = await customAPICallWithPayload(
        fetchURL,
        method,
        payload
      );
      if (response === "success") {
        let timeout = method === "POST" ? 7000 : 0;
        const searchText = localStorage.getItem("search");
        let randnumber = searchText ? Math.floor(Math.random() * 30) + 1 : 15;
        setSnackbar(true);
        setTimeout(() => {
          fetchData(
            `${baseURL}v1/team/?limit=${randnumber}&search=${searchText}`
          );
        }, timeout);
      }
    } catch (error) {
      console.error("API call failed:", error);
    } finally {
      setIsLoadingCal(false);
    }
  };

  useEffect(() => {
    fetchData(url);
  }, []);

  return (
    <>
      {isManagerOrSuperUser() ? (
        <>
          <TableWithExpandableRows
            teamsData={rowData}
            isLoading={isLoading}
            fetchTeamsSearchList={fetchTeamsSearchList}
            fetchMoreOnBottomReached={fetchMoreOnBottomReached}
            apicallForCalendar={apicallForCalendar}
            isLoadingCal={isLoadingCal}
          />

          <Snackbar
            open={snackbar}
            autoHideDuration={2000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setSnackbar(false)}
          >
            <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
              {SnackBarText(rotaAction)}
            </Alert>
          </Snackbar>
        </>
      ) : (
        <ErrorComponent
          errorMessage="You do not have access to view this page"
          handleRefresh={redirectToTicketSumary}
          firstButtonText="Go back"
        />
      )}
    </>
  );
}
export default Teams;
