import { useEffect, useState } from "react";
import { columns, slaStatusMessage, firstResponseMessage } from "./data";
import "./styles.css";
import DefaultDueDate from "../../assets/DefaultDueDate.svg";

import HighPriority from "../../assets/HighFlag.svg";
import LowPriority from "../../assets/LowFlag.svg";
import NormalPriority from "../../assets/NormalFlag.svg";
import UrgentPriority from "../../assets/flag_red_urgent.svg";

import DescOrder from "../../assets/DescOrder.png";
import AscOrder from "../../assets/AscOrder.png";
import ClickupSymbolLogo from "../../assets/ClickupSymbolLogo.png";
import NoRecords from "../../assets/empty state - no search result.svg";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

import { REACT_APP_API_PATH as baseURL } from "../../config";
import { SlaStatus } from "../SlaStatus";
import {
  checkAssignee,
  getShortDescription,
} from "../../utils/TicketsTableFunctions";
import { CircularLoader } from "../CircularLoader";
import { updateDateToHumanFormat } from "../../utils/common_functions";

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

export const TicketsTable = (props) => {
  const debouncedParameters = useDebounce(props.parameters, 300);

  const handleScroll = (e) => {
    const clientHeight = e.target.clientHeight;
    const scrollTop = e.target.scrollTop;
    const scrollHeight = e.target.scrollHeight;

    const sum = Math.trunc(clientHeight + scrollTop - scrollHeight);

    if (sum >= -100 && props.isLoading === false) {
      props.fetchDataForTicketsTable();
    }
  };

  useEffect(() => {
    const tableForTickets =
      document.getElementsByClassName("all_records_table")[0];

    const noRecordsImage =
      document.getElementsByClassName("no_records_found")[0];

    if (props.countTickets > 0) {
      noRecordsImage.style.display = "none";
      tableForTickets.style.display = "table";
      tableForTickets.style.visibility = "visible";
      tableForTickets.parentElement.style.border =
        "0.5px solid var(--Base-Blue-grey-5, #C0C3CE)";
      tableForTickets.parentElement.style.borderRadius = "0.25rem";
    } else if (props.countTickets === 0) {
      noRecordsImage.style.display = "block";
      tableForTickets.style.visibility = "hidden";
      tableForTickets.parentElement.style.border = "none";
    }
  }, [props.countTickets]);

  useEffect(() => {
    const searchParams = window.location.search;

    if (searchParams) {
      if (debouncedParameters) {
        props.setRowData([]);
        props.fetchDataForTicketsTable(
          `${baseURL}v2/ticket/?limit=15${debouncedParameters}`
        );
      }
    } else {
      props.fetchDataForTicketsTable(
        `${baseURL}v2/ticket/?limit=15${props.parameters}`
      );
    }
  }, [debouncedParameters, props.parameters]);

  useEffect(() => {
    const tableDivHeight =
      document.getElementsByClassName("ticketsTable")[0].clientHeight;
    const tableDataHeight =
      document.getElementsByClassName("all_records_table")[0].clientHeight;

    if (
      !props.isLoading &&
      tableDataHeight <= tableDivHeight &&
      props.rowData.length > 1
    )
      props.fetchDataForTicketsTable();
  }, [props.rowData?.length]);

  const checkDueDate = (dueDate, slaStatus) => {
    if (!dueDate) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: "16px",
            flex: "1 0 0",
            alignSelf: "stretch",
            marginLeft: "1.2rem",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              padding: "0",
              alignItems: "center",
            }}
          >
            <img alt={"due date"} src={DefaultDueDate} />
          </div>
        </div>
      );
    }

    return (
      <div className={slaStatus === "Breached" ? "dueDatePassed" : ""}>
        {updateDateToHumanFormat(dueDate)}
      </div>
    );
  };

  const checkPriority = (priority) => {
    if (priority === "high") {
      return (
        <div className="priority">
          <div className="priority_image">
            <img src={HighPriority} loading="lazy" alt="priority_image" />
          </div>
          <div className="priority_text">{priority.toUpperCase()}</div>
        </div>
      );
    } else if (priority === "low") {
      return (
        <div className="priority">
          <div className="priority_image">
            <img src={LowPriority} loading="lazy" alt="priority_image" />
          </div>
          <div className="priority_text">{priority.toUpperCase()}</div>
        </div>
      );
    } else if (priority === "normal") {
      return (
        <div className="priority">
          <div className="priority_image">
            <img src={NormalPriority} alt="priority_image" />
          </div>
          <div className="priority_text">{priority.toUpperCase()}</div>
        </div>
      );
    } else if (priority === "urgent") {
      return (
        <div className="priority">
          <div className="priority_image">
            <img src={UrgentPriority} loading="lazy" alt="priority_image" />
          </div>
          <div className="priority_text">{priority.toUpperCase()}</div>
        </div>
      );
    }
  };

  const checkSlaStatusGeneric = (statusType, messageMap) => {
    return (
      <SlaStatus
        slaStatusType={statusType}
        tooltipText={messageMap[statusType]}
      />
    );
  };

  const checkSlaStatus = (slaStatus) => {
    return checkSlaStatusGeneric(slaStatus, slaStatusMessage);
  };

  const checkFirstResponseSla = (firstResponseSla) => {
    return checkSlaStatusGeneric(firstResponseSla, firstResponseMessage);
  };

  const generateOrderingSymbol = (columnName) => {
    if (props.orderedColumn.columnName === columnName)
      if (props.orderedColumn.forward) return <img alt={"up"} src={AscOrder} />;
      else return <img alt={"down"} src={DescOrder} />;
    else return <></>;
  };

  const highlightSelectedColumn = (colIndex) => {
    const colCollection = Array.from(document.querySelectorAll("th"));

    colCollection.map((thElem) => {
      thElem.classList.remove("elem_highlighted");
      return 1;
    });

    colCollection[colIndex].classList.add("elem_highlighted");
  };

  const handleOnClickTableHeader = (column, index) => {
    highlightSelectedColumn(index);
    props.handleSelectedColumns(
      column,
      props.handleSetSelectedColumn,
      props.orderedColumn
    );
  };

  return (
    <div className="ticketsTable_component">
      {props.countTickets > 0 ? (
        <div className="count_tickets">{`${props.countTickets} tickets`}</div>
      ) : (
        <div className="count_tickets"></div>
      )}
      <div className="ticketsTable" onScroll={handleScroll}>
        <table className="all_records_table">
          <thead>
            <tr>
              {columns.map((column, index) => {
                return (
                  <th
                    key={column}
                    onClick={(e) => {
                      handleOnClickTableHeader(column, index);
                    }}
                  >
                    <div className="table_header_with_icons">
                      <div>{column}</div>
                      <div className="table_header_icon">
                        {generateOrderingSymbol(column)}
                      </div>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {props.rowData?.map((row, index) => {
              return (
                <tr key={row.id}>
                  <td>
                    {" "}
                    <Tooltip title={row.title} placement="right-end" arrow>
                      {getShortDescription(row.title)}
                    </Tooltip>
                  </td>
                  <td>{row.status}</td>
                  <td
                    className="slaStatus"
                    style={{
                      paddingLeft: "1.5rem",
                    }}
                  >
                    <Tooltip
                      title={row.first_response_sla_achieved}
                      placement="right-end"
                      arrow
                    >
                      {checkFirstResponseSla(
                        row.first_response_sla_achieved
                          ? "Achieved"
                          : "Breached"
                      )}
                    </Tooltip>
                  </td>

                  <td
                    className="slaStatus"
                    style={{
                      paddingLeft: "1.5rem",
                    }}
                  >
                    <Tooltip title={row.sla_status} placement="right-end" arrow>
                      {checkSlaStatus(row.sla_status)}
                    </Tooltip>
                  </td>
                  <td>{row.issue_category || "N/A"}</td>
                  <td className="description">
                    <Tooltip
                      title={row.description}
                      placement="right-end"
                      arrow
                    >
                      {getShortDescription(row.description)}
                    </Tooltip>
                  </td>
                  <td
                    style={{
                      paddingLeft: "2.3rem",
                    }}
                  >
                    <Tooltip
                      title={getShortDescription(row.clickup_link)}
                      placement="right-end"
                      arrow
                    >
                      <a href={row.clickup_link} target="_blank">
                        <img
                          src={ClickupSymbolLogo}
                          alt={"link"}
                          className="clickupLogo"
                        />
                      </a>
                    </Tooltip>
                  </td>
                  <td
                    style={{
                      paddingLeft: "1.2rem",
                    }}
                  >
                    <Tooltip
                      title={row.assignee ? row.assignee : "No assignee"}
                      placement="right-end"
                      arrow
                    >
                      {checkAssignee(row.assignee, row.assignee_color_code)}
                    </Tooltip>
                  </td>
                  <td>{row.team}</td>
                  <td>{row.sub_team || "N/A"}</td>
                  <td>{checkPriority(row.priority)}</td>
                  <td>{updateDateToHumanFormat(row.created_at)}</td>
                  <td>{checkDueDate(row.due_date, row.sla_status)}</td>
                  <td>{checkDueDate(row.completed_at, "")}</td>
                </tr>
              );
            })}
            {props.isLoading && (
              <tr>
                <td
                  colSpan={columns.length}
                  style={{ textAlign: "center" }}
                  data-testid="circular-loader"
                >
                  <CircularLoader />
                </td>
              </tr>
            )}
            {!props.url && !props.isLoading && (
              <tr>
                <td
                  colSpan={columns.length}
                  style={{ textAlign: "center" }}
                  className="no_more_data"
                >
                  No more data
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="no_records_found" style={{ display: "none" }}>
          <img
            src={NoRecords}
            alt={"Norecord"}
            style={{ width: "5rem", height: "5rem" }}
          />
          <div
            style={{
              color: "var(--Base-Blue-grey-10, #3C3E49)",
              fontFamily: "Rubik",
              fontSize: "1.25rem",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "2rem",
            }}
          >
            No matching tickets found.
          </div>
          <Button
            style={{
              textTransform: "none",
              color: "var(--Interactive-Bg-Purple-5, #6200EA)",
              fontFamily: "Rubik",
              fontWeight: "500",
              lineheight: "1.5rem",
            }}
            variant="text"
            onClick={props.clearAllSelectedFilters}
          >
            Clear filters
          </Button>
        </div>
      </div>
    </div>
  );
};
