import SearchIcon from "../../assets/Search.svg";
import ClearIcon from "@mui/icons-material/Clear";
import Plus from "../../assets/Plus.svg";
import Button from "@mui/material/Button";

const SearchField = (props) => {
  const {
    searchFieldStyle,
    inputValue,
    handleInputChange,
    updateHandleInputChange,
    heading,
    handleClearSearch,
    buttonDisplay,
    buttonTitle,
    handleAdd,
  } = props;

  const placeholder = heading?.props?.children;

  return (
    <div className="div-15" style={searchFieldStyle}>
      <span className="span-8">
        <img className="img-8" loading="lazy" src={SearchIcon} alt="serach" />
        <input
          type="text"
          value={inputValue}
          onChange={(e) =>
            handleInputChange(e.target.value, updateHandleInputChange)
          }
          className="div-16"
          placeholder={`Search ${
            placeholder?.includes("Tickets")
              ? `in ${placeholder}`
              : `${placeholder}`
          } `}
        />
        {inputValue ? (
          <ClearIcon
            sx={{
              cursor: "pointer",
              color: "rgba(0, 0, 0, 0.54)",
              width: "0.9em",
              marginLeft: "1.2rem",
            }}
            onClick={handleClearSearch}
          />
        ) : (
          ""
        )}
      </span>

      <Button
        style={{
          background: "#f3ebff",
          color: "color: var(--Interactive-Label-P1-P2-E1, #262626)",
          textTransform: "capitalize",
          display: `${buttonDisplay}`,
          height: "2.5rem",
        }}
        variant="contained"
        startIcon={
          <img loading="lazy" src={Plus} className="img-9" alt="plus" />
        }
        onClick={handleAdd}
      >
        {buttonTitle}
      </Button>
    </div>
  );
};

export default SearchField;
